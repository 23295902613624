<template>
<div>
  <b-container fluid class="mb-3" v-if="!loading">
    <h4 class="card-title">{{item.name}}</h4>
    <search-interface namespace="metrics" :rows="metrics" v-model="rowsSearched"></search-interface>
    <b-pagination
      inline
      v-model="currentPage"
      :total-rows="rowsSearched.length"
      :per-page="perPage"
      class="mt-3"
    ></b-pagination>
    <div class="mb-3" v-for="(item, index) in showRowsPage" :key="'metric-'+ item.id + '-' + index">
      <line-item :item="item" :trim="400"></line-item>
    </div>
    <b-pagination
      inline
      v-model="currentPage"
      :total-rows="rowsSearched.length"
      :per-page="perPage"
      class="mt-3"
    ></b-pagination>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import delay from '@/libs/delay'
import LineItem from '@/views/metrics/MetricSimple.vue'
import SearchInterface from '@/components/SearchInterfaceV1.vue'

export default {
  name: 'DataElement',
  components: {
    LineItem,
    SearchInterface
  },
  computed: {
    metricsAll: function () {
      return this.$store.state.metrics
    },
    reportingframeworks: function () {
      return this.$store.state.reportingframeworks
    },
    showRowsPage: function () {
      const start = (this.currentPage - 1) * this.perPage
      const end = this.currentPage * this.perPage
      return this.rowsSearched.slice(start, end)
    }
  },
  created: function () {
    this.load()
  },
  data () {
    const data = {
      item: {},
      loading: true,
      metrics: [],
      perPage: 20,
      currentPage: 1,
      rowsFiltered: [],
      rowsSearched: [],
      rowsSorted: [],
      sortProperties: [
        { value: 'name', text: 'Name' }
      ]
    }
    return data
  },
  methods: {
    load: async function () {
      this.loading = true
      this.item = this.reportingframeworks.find(item => item.id === Number.parseInt(this.$route.params.id))
      // find all metrics where metric.reportingframeworks contains a record with id === this.$route.params.id
      const metrics = this.metricsAll.filter(metric => metric.reportingframeworks.find(framework => framework.id === Number.parseInt(this.$route.params.id)))
      this.metrics = _.orderBy(_.uniqBy(metrics, 'id'), ['name'], ['asc'])
      console.log(metrics)
      await delay(100)
      this.loading = false
    }
  },
  watch: {
    '$route': function () {
      this.load()
    }
  }
}
</script>
